import { db } from 'wrFirebase/connection'
import * as firebase from 'firebase/app'
import { boundingBoxCoordinates } from 'utils/geoUtils'

export const getDispensariesByGeo = async ({ latitude, longitude, radius = 16.0934 }) => {
	// calculate the SW and NE corners of the bounding box to query for
	const box = boundingBoxCoordinates({ latitude, longitude }, radius)

	// construct the GeoPoints
	const lesserGeopoint = new firebase.firestore.GeoPoint(box.swCorner.latitude, box.swCorner.longitude)
	const greaterGeopoint = new firebase.firestore.GeoPoint(box.neCorner.latitude, box.neCorner.longitude)
	// construct the Firestore query
	const geoDispensaries = await db
		.collection('dispensaries')
		.where('geoLocation', '>', lesserGeopoint)
		.where('geoLocation', '<', greaterGeopoint)
		.get()

	const lonMax = longitude + 0.0181818181818182 * radius
	const lonMin = longitude - 0.0181818181818182 * radius
	const geoDispensaryObjects = geoDispensaries.empty
		? []
		: geoDispensaries.docs.map(doc => {
				const { geoLocation, ...rest } = doc.data()
				const formattedGeo = {
					_latitude: geoLocation.latitude,
					_longitude: geoLocation.longitude,
				}
				return { id: doc.id, ...rest, geoLocation: formattedGeo }
		  })
	return geoDispensaryObjects.filter(d => {
		// geoPoint query in firestore only handles latitude
		// so we'll have to filter out longitude by the range manually
		// eslint-disable-next-line no-underscore-dangle
		const lonToTest = d.geoLocation._longitude
		return lonToTest < lonMax && lonToTest > lonMin
	})
}

export const getDispensaryById = async id => {
	const dispensaryRef = await db
		.collection('dispensaries')
		.doc(id)
		.get()
	return !dispensaryRef.empty ? { id: dispensaryRef.id, ...dispensaryRef.data() } : {}
}

export const getDispensariesByState = async state => {
	const dispensariesRef = await db
		.collection('dispensaries')
		.where('state', '==', state)
		.get()
	return dispensariesRef.docs.length > 0 ? dispensariesRef.docs.map(d => ({ id: d.id, ...d.data() })) : []
}
