module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Weedrater","short_name":"Weedrater","start_url":"/","background_color":"#00AF66","theme_color":"#00AF66","display":"standalone","icon":"static/images/icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W5R68ZD","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-clean-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://demo.weedrater.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
