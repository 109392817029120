import { db } from './connection'

export const doCreateReview = async review => {
	try {
		const dateCreated = new Date(Date.now())
		const userReviewRef = await db.collection('reviews').add({ dateCreated, ...review })
		return { id: userReviewRef.id, dateCreated, ...review }
	} catch (error) {
		throw new Error(error.message)
	}
}

export const getAllReviews = async (reviewNodeType, reviewNodeId) => {
	try {
		const reviewsRef = await db
			.collection('reviews')
			.where('reviewType', '==', reviewNodeType)
			.where('reviewNodeID', '==', reviewNodeId)
			.get()
		const reviews = reviewsRef.docs.map(r => {
			const data = r.data()
			const { dateCreated, ...rest } = data

			return { id: r.id, dateCreated: new Date(dateCreated.toDate()).toUTCString(), ...rest }
		})
		return { reviews }
	} catch (error) {
		throw new Error(error.message)
	}
}
