import { db } from 'wrFirebase/connection'
import { NODE_TYPES } from 'utils/constants'
import { sleep } from 'utils/miscHelpers'
import { sortBy2Keys } from 'utils/arrayHelpers'

const inventoryCollectionRef = db.collection('inventory')
const pricingCollectionRef = db.collection('inventoryPrices')

export const inventoryQuery = (nodeId, nodeType) => {
	if (nodeType === NODE_TYPES.DISPENSARIES) {
		return inventoryCollectionRef.where('dispensary', '==', nodeId)
	}
	if (nodeType === NODE_TYPES.STRAINS) {
		return inventoryCollectionRef.where('strain', '==', nodeId)
	}
	return new Error('Bad nodeType for inventoryQuery')
}

export const pricesQuery = dispensaryId => {
	return pricingCollectionRef.where('dispensary', '==', dispensaryId)
}

async function changeFirstPrice({ priceId, price }) {
	const priceRef = pricingCollectionRef.doc(priceId)
	try {
		await db.runTransaction(async function(transaction) {
			const priceDoc = await transaction.get(priceRef)
			if (!priceDoc.exists) {
				throw Error('Invalid Pricing Id')
			}

			await transaction.update(priceRef, { price })
			return priceDoc.data()
		})
	} catch (e) {
		console.log(e.message)
	}
}

async function addPriceItem(props) {
	const { inventory, dispensary, uomText, uom, price } = props
	const priceItem = await pricingCollectionRef.add({
		dispensary,
		inventory,
		uomText,
		uom,
		price: `${parseInt(price) + 5}`,
	})
	await sleep(deletePriceItem, 4000, {
		priceId: priceItem.id,
	})
}
async function deletePriceItem({ priceId }) {
	const priceDoc = await pricingCollectionRef.doc(priceId).delete()
}
export const demoInventoryUpdates = async dispensaryId => {
	const inventory = await inventoryCollectionRef.where('dispensary', '==', dispensaryId).get()

	const inventoryIds = sortBy2Keys(
		inventory.docs.map(d => ({ id: d.id, ...d.data() })),
		'category',
		'productName'
	)

	const firstItem = inventoryIds[0].id
	const prices = await pricingCollectionRef
		.where('dispensary', '==', dispensaryId)
		.where('inventory', '==', firstItem)
		.get()

	const priceId = prices.docs[0].id

	const { price, uom, uomText } = prices.docs[0].data()

	await sleep(changeFirstPrice, 500, {
		priceId,
		price: parseInt(price) + 10,
	})
	await sleep(changeFirstPrice, 4000, {
		priceId,
		price: price,
	})
	await sleep(addPriceItem, 4000, {
		priceId,
		price,
		uom,
		uomText,
		dispensary: dispensaryId,
		inventory: firstItem,
	})
	return ''
}
